import { FURTHER_INFO_ROUTE } from "@app/core/further-info/[id]/constant";
import { NOTICES_ROUTE } from "@app/core/notices/[id]/constant";
import { FormIdentifier } from "@app/products/crms/model";
import {
  ActionTypes,
  IPPRActions,
  Svc_ActionTypes,
} from "@app/products/town-planning/ppr/actions/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ACTIONS_ROUTE } from "@common/pages/actions/[id]/constant";
import { getActionTypeLovs } from "@common/pages/actions/api";
import { ActionDeleteAuthorise } from "@common/pages/actions/model";
import { APPEAL_ROUTE } from "@common/pages/appeal/_id/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const isInclude = (value: string, list: any[]) => {
  return list.findIndex((item: any) => item.Value === value);
};

export const getUrlForAction = (
  dataItem: IPPRActions,
  data: Svc_ActionTypes
) => {
  if (data) {
    switch (true) {
      case dataItem.Type === ActionTypes.FurtherInfoRequest:
        return `${FURTHER_INFO_ROUTE}/${dataItem.ID}`;
      case dataItem.Type === ActionTypes.Appeal:
        return `${APPEAL_ROUTE}/${dataItem.ID}`;
      case isInclude(dataItem.Type, data?.ActionTypes) !== -1:
        return `${ACTIONS_ROUTE}/${dataItem.ID}`;
      case isInclude(dataItem.Type, data?.NoticeTypes) !== -1:
        return `${NOTICES_ROUTE}/${dataItem.ID}`;
      default:
        return `${ACTIONS_ROUTE}/${dataItem.ID}`;
    }
  }
};

export const loadActionTypeConfig = async (
  defaultConfig: IColumnFields[],
  columnChange: string
): Promise<IColumnFields[] | APIResponseError> => {
  const response = await getActionTypeLovs();
  if (isSuccessResponse(response) && response.data?.ReturnObj) {
    const data = response.data.ReturnObj;

    const newCols = defaultConfig.map((item: IColumnFields) =>
      item.field === columnChange
        ? { ...item, linkTo: (dataItem) => getUrlForAction(dataItem, data) }
        : item
    );
    return newCols;
  } else {
    return {
      status: response?.status,
      error: response?.error,
    } as APIResponseError;
  }
};

// Config for list action view
export const actionLaunchRoute = (formIdentifierENUM?: number) => {
  switch (formIdentifierENUM) {
    case FormIdentifier.Core_Form_Action:
      return ACTIONS_ROUTE;
    case FormIdentifier.Core_Form_Notice:
      return NOTICES_ROUTE;
    case FormIdentifier.Core_Form_ActionPlan:
    case FormIdentifier.Core_Form_VCATReview:
    case FormIdentifier.Core_Form_Advertising:
    case FormIdentifier.Core_Form_Prosecution:
    case FormIdentifier.Core_Form_Complaint:
      return ``; //@TODO will handle in furture when have this manage page
    case FormIdentifier.Core_Form_FurtherInformationRequest:
      return FURTHER_INFO_ROUTE;
    default:
      return ACTIONS_ROUTE;
  }
};

export const convertFormIdentifierToRecordType = (
  formIdentifier: FormIdentifier
): RECORDTYPE => {
  switch (formIdentifier) {
    case FormIdentifier.Core_Form_Action:
      return RECORDTYPE.CORE_Action;
    case FormIdentifier.Core_Form_Notice:
      return RECORDTYPE.CORE_Notice;
    case FormIdentifier.Core_Form_ActionPlan:
      return RECORDTYPE.CORE_ActionPlan;
    case FormIdentifier.Core_Form_VCATReview:
      return RECORDTYPE.CORE_VCATReview;
    case FormIdentifier.Core_Form_Advertising:
      return RECORDTYPE.CORE_Advertising;
    case FormIdentifier.Core_Form_Prosecution:
      return RECORDTYPE.CORE_Prosecution;
    case FormIdentifier.Core_Form_Complaint:
      return RECORDTYPE.CORE_Complaint;
    case FormIdentifier.Core_Form_FurtherInformationRequest:
      return RECORDTYPE.CORE_FurtherInformationRequest;
    default:
      return RECORDTYPE.CORE_Action;
  }
};

export const checkAuthoriseDeteteActionButton = (
  recordType: RECORDTYPE | undefined,
  authorise: ActionDeleteAuthorise | undefined
) => {
  switch (recordType) {
    case RECORDTYPE.CORE_Action:
      return authorise?.FormAction;
    case RECORDTYPE.CORE_Notice:
      return authorise?.FormNotice;
    case RECORDTYPE.CORE_FurtherInformationRequest:
      return authorise?.FormFurtherInformationRequest;
    default:
      return false;
  }
};

export const conditionDisableBookmarkAction = (
  gridSelectedRows: any[],
  lastSelectedRow: any
) => {
  return (
    gridSelectedRows.length > 0 &&
    gridSelectedRows.filter(
      (element) =>
        element.FormIdentifier_ENUM === lastSelectedRow?.FormIdentifier_ENUM
    ).length !== gridSelectedRows.length
  );
};
